<template>
  <v-card>
    <v-card-title class="accent">
      <v-text-field
        style="max-width: 400px;"
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        clearable
        outlined
        solo
        flat
        dense
        hide-details
      ></v-text-field>

      <v-spacer></v-spacer>
      <v-btn @click="goToAdd" color="secondary" class="white--text">
        adicionar
      </v-btn>
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text>
      <v-data-table
        v-if="perfis"
        :headers="headers"
        :search="search"
        :items="perfis"
        item-key="id"
        :items-per-page="10"
        :sort-by="['created_at']"
        :sort-desc="true"
        class="data-tables data-tables__row-click"
        @click:row="goToPerfil"
      >
        <template v-slot:item.status="{ item }">
          {{ item.status | status }}
        </template>
        <!-- <template v-slot:item.created_at="{ item }">
          {{ item.created_at | dateFormat("dd/MM/yyyy") }}
        </template> -->
      </v-data-table>
    </v-card-text>

    <NovoEditPerfis
      v-if="dialogNovoEditPerfil"
      :dialogNovoEditPerfil.sync="dialogNovoEditPerfil"
      :item="item"
      :adicionar="adicionar"
      @fetch-perfis="getPerfis"
    />
  </v-card>
</template>

<script>
import { fetchPerfis } from "@/api/admin/perfis.js";

export default {
  name: "ListaPerfis",

  components: {
    NovoEditPerfis: () => import("../NovoEditPerfis"),
  },

  data() {
    return {
      loading: true,
      perfis: [],
      search: "",
      adicionar: true,
      dialogNovoEditPerfil: false,
      item: {},
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "Descricao",
          value: "descricao",
        },
        {
          text: "Status",
          value: "status",
        },
        // {
        //   text: "Data",
        //   value: "created_at",
        // },
      ];
    },
  },

  methods: {
    // addPerfil(perfil) {
    //   this.perfis.push(perfil);
    // },

    // editPerfil(perfil) {
    //   let index = this.perfis.findIndex((item) => item.id === perfil.id);

    //   if (index != -1) {
    //     // this.perfis[index] = perfil;
    //     this.$set(this.perfis, index, perfil);
    //   } else {
    //     this.$toast.error("Perfil nao encontrado!");
    //   }
    // },

    goToPerfil(item) {
      this.item = { ...item };
      this.dialogNovoEditPerfil = true;
      this.adicionar = false;
    },

    goToAdd() {
      this.item = null;
      this.dialogNovoEditPerfil = true;
      this.adicionar = true;
    },

    getPerfis() {
      this.loading = true;
      fetchPerfis()
        .then((response) => {
          this.perfis = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getPerfis();
  },
};
</script>

<style scoped lang="scss"></style>
